var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"greeting-settings"},[_c('page-title',{attrs:{"title":_vm.$t('common_settings_tab_remove_system')},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":_vm.saveButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',[_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('field_remove_system_message_alert')}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'remove_system_enter_message',
          'hasAnchor': true,
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'remove_system_leave_message',
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'remove_system_pin_message',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'remove_system_voice_chat_messages' ,
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'remove_system_boost_messages',
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'remove_system_group_change_message',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'remove_system_forum_message',
          'hasAnchor': true,
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }