




































































































































































































































































import BindChannelByCode, { BindChannelState } from "@/components/chat/BindChannelByCode.vue";
import { CHATKEEPER_BOT_LOGIN } from "@/includes/constants";
import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import { ChatConfigSettings } from "@/includes/types/Chat/types";

import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import { atSignedLogin, tgLoginLink } from "piramis-base-components/src/shared/utils/tgLoginHelpers";

import { Component, Emit, Mixins, VModel } from 'vue-property-decorator';
import { successNotification } from "@/includes/NotificationService";

@Component({
  components: {
    NotAvailableOptionsOverlay,
    BindChannelByCode,
    SwitchInput,
    PageTitle
  }
})
export default class JournalSettings extends Mixins(InputSetups, TariffsTagsHelper) {

  @VModel() model!: ChatConfigSettings['common_config']

  @Emit()
  saveButtonClick() {
    return true
  }

  handleOnChannelBind(bindData: BindChannelState): void {
    if (bindData.channel_id) {
      this.$store.dispatch('requestChatConfig', { id: this.$store.getters.chatId })
        .then(() => {
          successNotification(this.$t('journal_channel_bind_success_message').toString())
        })
    }
  }

  get bindChannelInfo(): string {
    const brandBotName = this.$store.state.chatState.chat?.brand?.botname

    if (brandBotName) {
      return this.$t('bind_channel_code_info_2', { '0': atSignedLogin(brandBotName), '1': tgLoginLink(brandBotName) }).toString()
    } else {
      return this.$t('bind_channel_code_info_2', { '0': `@ChatKeeperBot`, '1': tgLoginLink(CHATKEEPER_BOT_LOGIN) }).toString()
    }
  }
}
