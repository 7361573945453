var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"catalog-settings"},[_c('page-title',{attrs:{"title":_vm.$t('catalog_integration_page_title'),"subtitle":_vm.$t('catalog_integration_page_description')},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":_vm.saveButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',{staticClass:"mt-5"},[_c('div',{staticClass:"catalog-integration__content"},[_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model,
            'key': 'chat_catalog_visibility',
            'options': _vm.chatCatalogVisibilityOptions,
            'clearable': false
          }
        }}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model,
            'key': 'chat_catalog_category',
            'options': _vm.chatCatalogCategoryOptions,
            'clearable': false
          }
        }}}),_c('chips-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model,
            'key': 'chat_catalog_hash_tags',
          }
        }}}),_c('textarea-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model,
            'key': 'chat_catalog_description',
          }
        }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }