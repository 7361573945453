














































import CatalogSettings from "@/components/chat/CommonSettings/CatalogSettings.vue";
import JournalSettings from "@/components/chat/CommonSettings/JournalSettings.vue";
import { ChatConfig } from "@/includes/types/Chat/types";
import RemoveSystemSettings from "@/components/chat/CommonSettings/RemoveSystemSettings.vue";
import MainSettings from "@/components/chat/CommonSettings/MainSettings.vue";
import TopicSettings from "@/components/chat/CommonSettings/TopicSettings.vue";

import CenteredColumnLayout from "piramis-base-components/src/components/CenteredColumnLayout.vue";
import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import PiTabs from "piramis-base-components/src/components/PiTabs.vue";

import { Vue, Component, Watch } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from "vue-router";

export enum CurrentViewType {
  main = 'main',
  journal = 'journal',
  removeSystem = 'remove-system',
  catalog = 'catalog',
  topics = 'topics',
}

Component.registerHooks( [
  'beforeRouteLeave'
])

@Component({
  data() {
    return {
      CurrentViewType
    }
  },
  components: {
    TopicSettings,
    MainSettings,
    RemoveSystemSettings,
    JournalSettings,
    CatalogSettings,
    PiTabs,
    PageTitle,
    CenteredColumnLayout
  }
})
export default class ChatCommonSettings extends Vue {
  currentView: CurrentViewType = CurrentViewType.main

  @Watch('$route.params.tab')
  onTabParamChange(param: CurrentViewType) {
    if (param) {
      this.currentView = param
    }
  }

  get tabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('common_settings_tab_common').toString(),
        value: CurrentViewType.main,
        icon: {
          iconPack: 'feather',
          name: 'icon-globe'
        }
      },
      {
        label: this.$t('common_settings_tab_journal').toString(),
        value: CurrentViewType.journal,
        icon: {
          name: 'menu_book'
        }
      },
      {
        label: this.$t('common_settings_tab_remove_system').toString(),
        value: CurrentViewType.removeSystem,
        icon: {
          name: 'cleaning_services'
        }
      },
      ...this.$store.getters.isChatForum ? [ {
        label: this.$t('common_settings_tab_topics').toString(),
        value: CurrentViewType.topics,
        icon: {
          name: 'auto_awesome_motion'
        }
      } ] : [],
      {
        label: this.$t('common_settings_tab_catalog').toString(),
        value: CurrentViewType.catalog,
        icon: {
          name: 'apps'
        }
      },
    ]
  }

  get chat() {
    return this.$store.state.chatState?.chat
  }

  saveCommonSettings() {
    return this.$store.dispatch('saveChatConfigRequest', {
      config: this.$store.state.chatState.chat!.common_config
    })
  }

  updateTopics(topics: ChatConfig['topics']) {
    this.$store.commit('setObjectField', {
      object: this.$store.state.chatState.chat,
      objectPath: "topics",
      value: topics,
    })
  }

  updateUrlParamTab(tab: CurrentViewType) {
    if (tab !== this.$route.params.tab) {
      this.$router.replace({
        params: {
          tab
        }
      })
    }
  }

  confirmSaveOnLeave(onOk: () => Promise<void> | void, onCancel?: () => Promise<void> | void) {
    this.$confirm({
      title: this.$t('confirm_save_chat_config').toString(),
      okText: this.$t('confirm_save_before_leave_ok').toString(),
      cancelText: this.$t('confirm_save_before_leave_cancel').toString(),
      onOk,
      onCancel,
    })
  }

  onTabChange(tab: CurrentViewType) {
    if (tab !== this.currentView && this.$store.getters.isChatConfigChanged) {
      const onOk = this.saveCommonSettings
      const onCancel = () => this.$store.dispatch('rollbackChatConfig')

      this.confirmSaveOnLeave(onOk, onCancel)
    }

    this.currentView = tab
    this.updateUrlParamTab(tab)
  }

  beforeRouteLeave(to: Route, from:Route, next:NavigationGuardNext) {
    if (this.$store.getters.isChatConfigChanged) {
      const onOk = () => this.saveCommonSettings()
        .then(() => {
          next()
        })

      const onCancel = () => {
        this.$store.dispatch('rollbackChatConfig')

        next()
      }

      this.confirmSaveOnLeave(onOk, onCancel)

    } else {
      next()
    }
  }

  destroyed() {
    this.$baseTemplate.saveButton.resetSaveButtonCallback()
  }

  mounted() {
    this.$baseTemplate.saveButton.show(this.saveCommonSettings)

    const tab = this.$route.params.tab

    if (tab) {
      if (Object.values(CurrentViewType).includes(tab as CurrentViewType)) {
        this.currentView = tab as CurrentViewType
      } else {
        this.updateUrlParamTab(this.currentView)
      }
    } else {
      this.updateUrlParamTab(this.currentView)
    }
  }
}
